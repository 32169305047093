import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const ProgressRing = ({ value, size = 100, thickness = 4, color = 'primary' , className }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
      }}
      className={className} 
    >
      <CircularProgress
        variant="determinate"
        value={value}
        size={size}
        thickness={thickness}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>{`${Math.round(value)}%`}</div>
      </Box>
    </Box>
  );
};

export default ProgressRing;
