import React, { ReactDOM, useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import "./dropdown.css";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setIsCheck } from "../../store/features/dropdown/dropdownSlice";
import { setChecks } from "../../store/features/dropdown/checks";

export const Dropdown = (props) => {
  // const [data, setData] = useState([]);
  // let [isCheck, setIsCheck] = useState([]);
  const [data, setData] = useState([]);
  // const [checkedIds, setCheckedIds] = useState([]);

  const checkedIds = useSelector((state) => state.checks);
  const isCheck = useSelector((state) => state.dropdown);
  const dispatch = useDispatch();
  const refetch = true;

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_CMS_URL}/web/pages/planning/getLotameBehaviors.jsp`
    )
      .then((res) => {
        // console.log(props.n, "abc", [res.data[0]]);
        setData([res.data[props.n]]);
      })
      .catch((err) => console.log(err));
  }, []);

  const onCheckChange = (e, behavior) => {
    let _isCheck = [...isCheck];
  
    // Create a shallow copy of the behavior object
    let updatedBehavior = { ...behavior };
  
    if (e.checked) {
      if (props.operator) {
        updatedBehavior.operator = props.operator; // Modify the copy instead of the original
      }
      if (updatedBehavior?.behaviors) {
        delete updatedBehavior.behaviors;
      }
      updatedBehavior.isInclude = props.type;
      _isCheck.push(updatedBehavior);
    } else {
      _isCheck = _isCheck.filter((item) => {
        return item.behaviorId !== behavior.behaviorId;
      });
    }
  
    // Update the checkedIds correctly
    const ids = _isCheck.map((item) => item.behaviorId);
  
    // Dispatch the updated checkedIds and isCheck states to Redux store
    dispatch(setChecks(ids));
    dispatch(setIsCheck(_isCheck));
  };
  

  return (
    <div className="accordion-demo">
      <div className="accordionMainWrap">
        {data &&
          data.map((val) => {
            return (
              <div key={val.id} className="accordWrap">
                <Accordion>
                  <AccordionTab
                    expandIcon="pi pi-plus"
                    collapseIcon="pi pi-minus"
                    header={<span>{val.behaviorType}</span>}
                  >
                    <div className="AccCopy">
                      {val.data &&
                        val.data.map((lvl2) => {
                          return (
                            <div key={lvl2.behaviorId}>
                              {/* Checkboxes for lvl2 */}
                              <div className="innerSpanElement">
                                <Checkbox
                                  value={lvl2.behaviorId}
                                  onChange={(e) => onCheckChange(e, lvl2)}
                                  checked={checkedIds.includes(lvl2.behaviorId)}
                                />
                                <span>{lvl2.behaviorName}</span>
                              </div>

                              {lvl2.behaviors &&
                                lvl2.behaviors.map((lvl3) => {
                                  return (
                                    <div key={lvl3.behaviorId} >
                                      {/* Checkboxes for lvl3 */}
                                      <div  className="innerSpanElement">
                                        <Checkbox
                                          value={lvl3.behaviorId}
                                          onChange={(e) =>
                                            onCheckChange(e, lvl3)
                                          }
                                          checked={checkedIds.includes(
                                            lvl3.behaviorId
                                          )}
                                        />
                                        <span>{lvl3.behaviorName}</span>
                                      </div>

                                      {lvl3.behaviors &&
                                        lvl3.behaviors.map((lvl4) => {
                                          return (
                                            <div key={lvl4.behaviorId}>
                                              {/* Checkboxes for lvl4 */}
                                              <div  className="innerSpanElement">
                                                <Checkbox
                                                  value={lvl4.behaviorId}
                                                  onChange={(e) =>
                                                    onCheckChange(e, lvl4)
                                                  }
                                                  checked={checkedIds.includes(
                                                    lvl4.behaviorId
                                                  )}
                                                />
                                                <span>{lvl4.behaviorName}</span>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })}
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
            );
          })}
      </div>
    </div>
  );
};
