import React, { useState } from 'react';
import { Switch, FormControlLabel } from '@mui/material';

const ToggleSwitch = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isChecked}
          onChange={handleToggle}
          color="primary"
        />
      }
      label={isChecked ? 'On' : 'Off'}
    />
  );
};

export default ToggleSwitch;
