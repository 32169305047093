import { createSlice } from "@reduxjs/toolkit";

const checks = createSlice({
    name : "checks",
    initialState:[],
    reducers:{
        setChecks: (state, action)=>{
            console.log(action.payload, "from checks")
            return action.payload
        },
    }
}) ;
export const {setChecks} = checks.actions;
export default checks.reducer;