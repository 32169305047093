import React, { ReactDOM, useState } from "react";
import './audienceRow.css'
import { useSelector, useDispatch } from "react-redux";
import { setIsCheck } from "../../store/features/dropdown/dropdownSlice";
import { setChecks } from "../../store/features/dropdown/checks";

const AudienceNewRow = ({name, id}) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [idOf, SetIdOf] = useState(null);
    const isCheck = useSelector((state) => state.dropdown);
    const checkIds = useSelector((state)=> state.checks);
    const dispatch = useDispatch();

    const handleDelete = (val) => {
        const deletedData = isCheck.filter((val)=> val.behaviorId != id);
        const newcheckIds = checkIds.filter((val)=> val != id);

        dispatch(setIsCheck(deletedData))
        dispatch(setChecks(newcheckIds))
    }
  return (
<>
<div id="operatorFunction1Wrap" className="operatorFunction1Wrap">
    <h4>
        {/* <span>Retargetly</span> */}
        {name}
    </h4>
    <div className="operatorFunList">
        {/* <span>23.02M |</span> */}
        {/* <span className="disabledArrow"><a><i className="pi pi-arrow-up" /></a></span>
        <span><a><i className="pi pi-arrow-down" /></a></span> */}
        <span onClick={handleDelete}><a><i className="pi pi-times"></i></a></span>
        {/* <span><a><i className="pi pi-stop" /></a></span> */}
    </div>
</div>
</>
);
};

export default AudienceNewRow;