import React from "react";
import { Link } from "react-router-dom";
import { CiSettings } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { BsZoomIn } from "react-icons/bs";
import { MdZoomOutMap } from "react-icons/md";
import ProgressRing from "../../components/progress/progress";
import Switch from "../../components/toggleSwitch/toggleSwitch";
export default function page() {
  return (
    <>
      <hr className="mtb4" />

      <div className="container">
        <div className="colors-container pb4">
          <h2>Colors Block / Variables</h2>
          <div className="colors-block" style={{ display: "flex", flexWrap: "wrap", gap: "20px"}}>

      
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%" }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#1d4593",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0"> --primaryColor</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#f05b21",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0">--secondaryColor</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#000000",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0">--black</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#d3d5d5",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0">--gray</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#f1f1f1",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0">--lightgray</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#ffffff",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0">--white</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#1d2045",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0"> --secondaryColor2</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#6e85b1",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0"> --secondaryColor3</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#f27848",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0"> --secondaryColor4</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#595959",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0"> --text</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#f5d920",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0"> --yellow</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#d42419",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0"> --warning</span>
          </div>
          
          <div style={{ display: "flex", gap: "20px", alignItems: "center" , width: "28%"  }}>
            <span
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#1ebd5e",
                display: "block",
              }}
            />{" "}
            <span className="h4 mb0"> --success</span>
          </div>
          </div>
        </div>

        <h1 className="h1">This is h1</h1>
        <h2 className="h2">This is h2</h2>
        <h3 className="h3">This is h3</h3>
        <h4 className="h4">This is h4</h4>
        <h5 className="h5">This is h5</h5>
        <h6 className="h6">This is h6</h6>
        <p>This is paragraph</p>
        <p className="small">This is paragraph small</p>
        <p className="xsmall">This is paragraph extra small</p>
        <hr className="d80" />
        <p>Small buttons</p>
        <Link className="btn small">Primary</Link>
        <Link className="btn secondary small">Secondary</Link>
        <Link className="btn outline small">Outline</Link>
        <Link className="btn outline-secondary small">Outline</Link>
        <hr className="d40" />
        <p>Regular buttons</p>
        <Link className="btn">Primary</Link>
        <Link className="btn secondary">Secondary</Link>
        <Link className="btn outline">Outline</Link>
        <Link className="btn outline-secondary">Outline</Link>
        <hr className="d40" />
        <p>Large buttons</p>
        <Link className="btn large">Primary</Link>
        <Link className="btn secondary large">Secondary</Link>
        <Link className="btn outline large">Outline</Link>
        <Link className="btn outline-secondary large">Outline</Link>
        <hr className="d80" />
        <p>Regular Checkboxes</p>
        <input className="checkbx" type="checkbox" />
        <input className="checkbx secondary" type="checkbox" />
        <hr className="d40" />
        <p>Medium Checkboxes</p>
        <input className="checkbx medium" type="checkbox" />
        <input className="checkbx secondary medium" type="checkbox" />
        <hr className="d40" />
        <p>Large Checkboxes</p>
        <input className="checkbx large" type="checkbox" />
        <input className="checkbx secondary large" type="checkbox" />
        <hr className="d80" />
        <p>Icons</p>
        <CiSettings className="h2 mr2 primary-font" />
        <MdDeleteOutline className="h2 mr2 primary-font" />
        <BsZoomIn className="h3 mr2 primary-font" />
        <MdZoomOutMap className="h3 mr2 primary-font" />
        <hr className="d80" />
        <p>Progress Ring using MIUI</p>
        <div style={{ display: "flex", gap: "30px" }}>
          <ProgressRing
            value={25}
            size={60}
            thickness={4}
            color="secondary"
            className="primary-progress"
          />
          <ProgressRing
            value={50}
            size={60}
            thickness={4}
            color="secondary"
            className="primary-progress"
          />
          <ProgressRing
            value={75}
            size={60}
            thickness={4}
            color="secondary"
            className="primary-progress"
          />
        </div>
        <hr className="d80" />
        <p>Progress Ring using MIUI</p>
        <div style={{ display: "flex", gap: "30px" }}>
          <ProgressRing
            value={25}
            size={60}
            thickness={4}
            color="secondary"
            className="secondary-progress"
          />
          <ProgressRing
            value={50}
            size={60}
            thickness={4}
            color="secondary"
            className="secondary-progress"
          />
          <ProgressRing
            value={75}
            size={60}
            thickness={4}
            color="secondary"
            className="secondary-progress"
          />
        </div>
        <hr className="d80" />
        <p>Toggle Icon</p>
        <Switch
          // checked={isChecked}
          // onChange={handleToggle}
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: "#1ebd5e", // Custom green color when checked
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: "#1ebd5e", // Custom green track color when checked
            },
          }}
        />
      </div>
    </>
  );
}
