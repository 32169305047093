//Data Layer
import React, { useState, useEffect, useRef } from "react";
import AccordionDemo from "../../components/nestedCard/nestedCard";
// import "./audience.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "../../components/dropdown/dropdown";
import { Toast } from "primereact/toast";
import "primeicons/primeicons.css";
import AudienceNewRow from "../../components/audienceRow/audienceRow";
import { ProgressSpinner } from "primereact/progressspinner";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsCheck,
  deleteIsCheck,
} from "../../store/features/dropdown/dropdownSlice";
import { setChecks } from "../../store/features/dropdown/checks";
import { SelectButton } from "primereact/selectbutton";
import { uuid } from "uuidv4";

export default function Audience() {
  const [popupActive, setPopupActive] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [getOrAnd, setOrAnd] = useState([]);
  const [getExclude, setExclude] = useState([]);
  const [audienceName, setAudienceName] = useState("");
  const [impression, setImpression] = useState("0");
  let [boxstate, setBoxState] = useState(false);
  let [valid, setValid] = useState(false);
  let [validSave, setValidSave] = useState(false);
  let [loading, setLoading] = useState(false);
  // let [check, setIsCheck] = useState([]);
  const million = 1000000;
  const [millionConvertedtraiReach, setmillionConvertedtraiReach] =
    useState("0");
  const [
    millionConvertedcompositionIndexUv,
    setmillionConvertedcompositionIndexUv,
  ] = useState("0");
  const [millionConvertedavgMinVisitor, setmillionConvertedavgMinVisitor] =
    useState("0");

  const isCheck = useSelector((state) => state.dropdown);
  const dispatch = useDispatch();
  const geoBehaviors = isCheck.filter(
    (behavior) => behavior.behaviorType === "geo"
  );
  const genderBehaviors = isCheck.filter(
    (behavior) => behavior.behaviorType === "gender"
  );
  const lotameAudienceBehaviors = isCheck.filter(
    (behavior) => behavior.behaviorType === "affinity"
  );
  const customAudienceBehaviors = isCheck.filter(
    (behavior) => behavior.behaviorType === "website"
  );
  const ageBehaviors = isCheck.filter(
    (behavior) => behavior.behaviorType === "age"
  );

  const [inputval, setInputVal] = useState({
    noOfDays: "",
    budget: "",
    campaignName: "",
    objective: "",
  });

  const popupOpen = (e) => {
    if (popupActive) {
      setPopupActive(false);
    } else {
      setPopupActive(true);
    }
  };

  const changeData = (e) => {
    const { name, value } = e.target;
    setInputVal({ ...inputval, [name]: value });
  };
  // Or And Variables
  const options = ["AND", "OR"];
  const [value, setValue] = useState(options[0]);
  const [value1, setValue1] = useState(options[0]);

  const toast = useRef(null);

  const includeSections = [
    geoBehaviors,
    genderBehaviors,
    lotameAudienceBehaviors,
    customAudienceBehaviors,
    ageBehaviors,
  ];
  const excludeSections = [
    geoBehaviors,
    genderBehaviors,
    lotameAudienceBehaviors,
    customAudienceBehaviors,
    ageBehaviors,
  ];

  useEffect(() => {}, []);

  // ########## And /Or Block ###############
  const getAccordrowData = (data) => {
    setOrAnd(data);
  };

  const handelDelete = (id) => {
    dispatch(deleteIsCheck(id));
  };

  // End #########   And /Or Block ###############
  const getExcludeAccData = (data) => {
    setExclude(data);
  };

  // ############ Exclude block ################

  const calculate = async () => {
    let _behaviorList = [...isCheck.filter((items) => items.isInclude == true)];
    let _excludeBehaviorList = [
      ...isCheck.filter((items) => items.isInclude == false),
    ];

    console.log(isCheck, _behaviorList);

    let _data = {
      behaviors: _behaviorList,
      excludeBehaviors: _excludeBehaviorList,
      noOfDays: inputval?.noOfDays,
      budget: inputval?.budget,
    };

    let data = JSON.stringify(_data);
    console.log(
      _data,
      _excludeBehaviorList,
      "toCalc",
      _behaviorList,
      "behaviour List"
    );
    setLoading(true);
    Axios.post(
      `${process.env.REACT_APP_CMS_URL}/web/pages/planning/plannerDataLayerResponse.jsp`,
      data
    )
      .then((res) => {
        console.log("abccal", res.data);
        const traiReach = res.data.trai_reach.replace(/,/g, ""); 
        setImpression(traiReach);
        if (Number(traiReach) >= million) {
          const formattedNum = (Number(traiReach) / million).toFixed(2);
          setmillionConvertedtraiReach(formattedNum);
        } else {
          setmillionConvertedtraiReach(traiReach);
        }
      
        setmillionConvertedcompositionIndexUv(res.data.comosition_index_uv);
        setmillionConvertedavgMinVisitor(res.data.avg_min_visitor);
      
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success Message",
      detail: "Message Content",
      life: 3000,
    });
  };

  const onSave = async () => {
    let _behaviorList = [...getOrAnd];
    let _excludeBehaviorList = [...getExclude];
    console.log("test");
    // if (audienceName === "" && impression === 0) {
    //   return;
    // }

    let _data = {
      // audienceName: audienceName,
      // totalImpression: impression,
      // operator: selectedCity.name,
      noOfDays: inputval?.noOfDays,
      budget: inputval?.budget,
      objective: inputval?.objective,
      campaignName: inputval?.campaignName,
      type: "data layer",
      audienceId: uuid(new Date()),
      traiReach: "465.0",
      compositionIndexUv: "33.4",
      avgMinVisitor: "2.0",
      operator: "AND",
      behaviors: _behaviorList,
      excludeBehaviors: _excludeBehaviorList,
    };

    let data = JSON.stringify(_data);
    console.log("saved", data, audienceName, impression);

    Axios.post(
      `${process.env.REACT_APP_CMS_URL}/web/pages/planning/saveLotameCustomAudience.jsp`,
      data
    )
      .then((res) => {
        console.log("abccal", res.data);
        popupOpen();
        if (res?.data) {
          toast.current.show({
            severity: "success",
            summary: "Success Message",
            detail: "Audience Saved Successfully",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="mainWrap">
        <div className="newAudDetailHeader ptb4">
          <div className="newAudDetailList">
            <input
              className="newAudDetailInput planningInputs pl2"
              type="text"
              value={inputval?.noOfDays}
              name="noOfDays"
              placeholder="noOfDays"
              onChange={changeData}
            />

            <input
              className="newAudDetailInput planningInputs pl2"
              type="text"
              value={inputval?.budget}
              name="budget"
              placeholder="budget"
              onChange={changeData}
            />
            <div className="AudSaveCancelBtn">
              <button
                className="btn outline secondary"
                onClick={() => {
                  dispatch(setIsCheck([]));
                  dispatch(setChecks([]));
                }}
              >
                Clear
              </button>
              <button className="btn outline primary ml1" onClick={popupOpen}>
                Save
              </button>
            </div>
          </div>
          <h4 className="mb0">
            <span>
              <small>Trai Reach : </small>
              {loading ? (
                <ProgressSpinner
                  style={{ width: "15px", height: "15px" }}
                  strokeWidth="5"
                  fill="var(--surface-ground)"
                  animationDuration=".8s"
                />
              ) : (
                millionConvertedtraiReach
              )}
            </span>
            <span>
              <small>Composition Index UV : </small>
              {loading ? (
                <ProgressSpinner
                  style={{ width: "15px", height: "15px" }}
                  strokeWidth="5"
                  fill="var(--surface-ground)"
                  animationDuration=".8s"
                />
              ) : (
                millionConvertedcompositionIndexUv
              )}
            </span>
            <span>
              <small>AVG Min Visitor : </small>
              {loading ? (
                <ProgressSpinner
                  style={{ width: "15px", height: "15px" }}
                  strokeWidth="5"
                  fill="var(--surface-ground)"
                  animationDuration=".8s"
                />
              ) : (
                millionConvertedavgMinVisitor
              )}
            </span>
            {/* <span>Total Impression</span> */}
          </h4>
        </div>

        {/* section 2 */}
        <div className="detailsMainWrap">
          {includeSections.map((val, index) => {
            return (
              <div className="detailListWrap" key={index}>
                {/* include containers */}
                <div className="incluedCard mb20">
                  <div className="cardMainWrap">
                    <div className="card">
                      <div onClick={() => setBoxState(false)}>
                        <Dropdown
                          n={index}
                          type={true}
                          operator={
                            index == 3 ? value1 : index == 2 ? value : "AND"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="cardOverviewWrap">
                    <div
                      id="attributeOrAndWrap"
                      className={boxstate ? "" : "activeTab"}
                      onClick={() => {
                        setBoxState(false);
                        console.log(boxstate);
                      }}
                    >
                      <div id="operatorDDWrap" className="operatorDDWrap"></div>
                      <div>
                        <div
                          className={
                            index == 2
                              ? "flex toggleBtn"
                              : "flex hide toggleBtn"
                          }
                        >
                          <SelectButton
                            value={value}
                            onChange={(e) => {
                              setValue(e.value);
                              console.log(index);
                            }}
                            options={options}
                          />
                        </div>
                        <div
                          className={
                            index == 3
                              ? "flex toggleBtn"
                              : "flex hide toggleBtn"
                          }
                        >
                          <SelectButton
                            value={value1}
                            onChange={(e) => {
                              setValue1(e.value);
                              console.log(index);
                            }}
                            options={options}
                          />
                        </div>
                        {includeSections[index] &&
                        includeSections[index].length ? (
                          ""
                        ) : (
                          <span className="emptyList">No lists</span>
                        )}
                        {includeSections[index] &&
                          includeSections[index].map((val, index) => {
                            return (
                              <React.Fragment key={index}>
                                {val.isInclude == true ? (
                                  <AudienceNewRow
                                    name={val.behaviorName}
                                    id={val.behaviorId}
                                    handleDelete={() =>
                                      handelDelete(val.behaviorId)
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>

                {/* exclude containers */}
                <div className="incluedCard mb20">
                  <div className="cardMainWrap">
                    <div className="card">
                      <div onClick={() => setBoxState(true)}>
                        <Dropdown n={index} type={false} operator="AND" />
                      </div>
                    </div>
                  </div>
                  <div className="cardOverviewWrap">
                    <div
                      id="attributeOrAndWrap"
                      className={boxstate ? "activeTab" : ""}
                      onClick={() => {
                        setBoxState(true);
                        console.log(boxstate);
                      }}
                    >
                      <div id="operatorDDWrap" className="operatorDDWrap"></div>
                      <div>
                        {excludeSections[index] &&
                        excludeSections[index].length ? (
                          <>
                            {excludeSections[index] &&
                              excludeSections[index].map((val, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {val.isInclude == false ? (
                                      <AudienceNewRow
                                        name={val.behaviorName}
                                        id={val.behaviorId}
                                        handleDelete={() =>
                                          handelDelete(val.behaviorId)
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </React.Fragment>
                                );
                              })}{" "}
                          </>
                        ) : (
                          <span className="emptyList">No lists</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* section 2 closed */}

        <div className="flex items-center pb4">
          <button
            className="btn"
            type="submit"
            onClick={calculate}
            // disabled={!valid}
          >
            Calculate
          </button>
        </div>
        <div className={`savePopup  ${popupActive ? "active" : ""}`}>
          <div className="newAudDetailList ">
            <i
              className="pi-times pi"
              onClick={popupOpen}
              style={{ fontSize: "1rem" }}
            ></i>
            <InputText
              className="newAudDetailInput"
              type="text"
              name="campaignName"
              placeholder="campaignName"
            />

            <InputText
              className="newAudDetailInput"
              type="text"
              name="objective"
              placeholder="objective"
            />
            <div className="AudSaveCancelBtn">
              <Button
                className="btn"
                label="Save"
                type="submit"
                onClick={onSave}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
